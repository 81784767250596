.preload-page {
    margin: 0;
    padding: 0;
    overflow: hidden;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in-out;
}

.preloader {
    display: flex;
    justify-content: center;
    align-items: center;
}

.preload-page.hide {
    opacity: 0;
    visibility: hidden;
}

.preload-page .preloader {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    color: #f5f5f5;
}