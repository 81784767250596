.contenedor-information {
    margin-top: 10%;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 5%;
}

.row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;

}

@media (min-width: 768px) {
    .offset-md-1 {
        margin-left: 8.33333%;
    }
}

@media (min-width: 768px) {
    .col-md-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66667%;
    }
}

@media (min-width: 992px) {
    .align-items-lg-center {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }
}

@media (min-width: 992px) {
    .justify-content-lg-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }
}

@media (min-width: 992px) {
    .d-lg-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }
}

@media (min-width: 991px) {

    .slogan-1,
    h2 {
        min-width: 380px;
        margin-bottom: 0;
        margin-right: 30px;
    }
}

.slogan-1 {
    color: #002776;
    font-size: 36px;
    font-weight: 700;
    line-height: 1.1;
    margin: 0;
}

.slogan-1 .slogan-2,
h2 span {
    display: block;
    color: #ff6319;
}

.info-section {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.info-section {
    font-size: 20px !important;
    color: #000;
    margin: 0;
    padding-top: 12px;
}

.btn-more {
    margin-top: 30px;
    color: #002776;
    box-shadow: inset 0 0 0 1px #002776;
    min-width: 150px;
    padding: 0 25px;
    text-align: center;
    line-height: 38px;
    font-size: 15px;
    border-radius: 50px;
    display: inline-block;
    position: relative;
    overflow: hidden;
    z-index: 2;
    text-decoration: none;
}

.btn-15 {
    background: #fff;
    border: none;
    z-index: 1;
}

.btn-15:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background-color: #002776;
    border-radius: 5px;
    box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
        7px 7px 20px 0px rgba(0, 0, 0, .1),
        4px 4px 5px 0px rgba(0, 0, 0, .1);
    transition: all 0.3s ease;
}

.btn-15:hover {
    color: #fff;
}

.btn-15:hover:after {
    left: 0;
    width: 100%;
}

.btn-15:active {
    top: 2px;
}