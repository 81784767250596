.footer-container {
    background-color: #002777;
    /* padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri;
}

.footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
}

.footer-subscription>p {
    font-family: 'Gill Sans', Calibri;
}

.footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
}

.footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
}

.footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
}

.footer-horario {
    color: #fff;
    align-items: center;
    font-weight: 900;
}

.footer-horario .subtitle {
    text-align: justify;
}

h4 {
    font-size: 2.5rem;
}

.work-hour {
    align-items: center;
    font-size: 25px;
}

.footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
}

.footer-link-wrapper {
    display: flex;
}

.footer-link-items {
    display: flex;
    flex-direction: row;
    margin: 16px;
    box-sizing: border-box;
    justify-content: space-between;
}

.footer-link-items h2 {
    margin-bottom: 16px;
    font-size: 24px;
}

.footer-link-items>h2 {
    color: #fff;
}

.footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
}

.footer-email-form h2 {
    margin-bottom: 2rem;
}

.footer-input::placeholder {
    color: #b1b1b1;
}

.dunlop-logo {
    margin-top: 20px;
    align-items: center;
}

.social-icons {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    /* width: 80px; */
}

.social-icons:hover {
    color: #FF6318;
    font-size: 40px;
    transition: ease-in-out 0.2s;
}

.social-media {
    max-width: 1000px;
    width: 100%;
    align-items: center;
}

.social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
}

.social-logo {
    color: #fff;
    cursor: pointer;
    align-items: center;
}

.website-rights {
    color: #fff;
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 16px;
}

.dunlop-ven {
    color: #fff !important;
}

.dunlop-ven:hover {
    color: #FF6318 !important;
}

@media screen and (max-width: 820px) {
    .footer-links {
        padding-top: 2rem;
    }

    .footer-input {
        width: 100%;
    }

    .btn {
        width: 100%;
    }

    .footer-link-wrapper {
        flex-direction: column;
    }

    .social-media-wrap {
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {}