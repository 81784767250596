.transmission-general {
    /* font-family: "Open Sans", sans-serif; */
    height: 100vh;
    overflow: hidden;
    margin: 0;
}

.enlace-transmission {
    text-decoration: none;
    text-align: center;
}

.title-transmission {
    font-weight: 800;
    font-size: 3rem;
    text-align: center;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    height: 100vh;
}

.second-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    height: 100vh;
}

.split {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-blend-mode: overlay;
}

.second-split {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-blend-mode: overlay;
}

/* Hovers */

/* Primer Split Hover */

.left-transmission:hover {
    width: 150%;
    height: 100vh;
    transform: scale(1.0);
}

.right-transmission:hover {
    width: 150%;
    height: 100vh;
    transform: scale(1.0);
}

.central-transmission:hover {
    width: 150%;
    height: 100vh;
    transform: scale(1.0);
}

/* Segundo Split Hover */

.second-left-transmission:hover {
    width: 150%;
    height: 100vh;
    transform: scale(1.0);
}

.second-right-transmission:hover {
    width: 150%;
    height: 100vh;
    transform: scale(1.0);
}

.second-central-transmission:hover {
    width: 150%;
    height: 100vh;
    transform: scale(1.0);
}

/* Tercer Split Hover */

.third-left-transmission:hover {
    width: 150%;
    height: 100vh;
    transform: scale(1.0);
}

.third-right-transmission:hover {
    width: 150%;
    height: 100vh;
    transform: scale(1.0);
}

.third-central-transmission:hover {
    width: 150%;
    height: 100vh;
    transform: scale(1.0);
}

/* Cuarto Split Hover */

.fourth-left-transmission:hover {
    width: 150%;
    height: 100vh;
    transform: scale(1.0);
}

.fourth-right-transmission:hover {
    width: 150%;
    height: 100vh;
    transform: scale(1.0);
}

.fourth-central-transmission:hover {
    width: 150%;
    height: 100vh;
    transform: scale(1.0);
}

/* Fin Hovers */

/* Splits */

/* Primer Split */

.left-transmission {
    background-image: url("../../../../public/images/productos/Transmision/transmission-atf-presentacion.jpg");
    width: 100%;
    height: 100vh;
}

.right-transmission {
    background-image: url("../../../../public/images/productos/Transmision/transmission-atf-ficha.jpg");
    width: 100%;
    height: 100vh;
}

.central-transmission {
    background-image: url("../../../../public/images/productos/Transmision/transmission-atf.jpg");
    width: 100%;
    height: 100vh;
}

/* Segundo Split */

.second-left-transmission {
    background-image: url("../../../../public/images/productos/Transmision/transmission-gera-ep-presentacion.jpg");
    width: 100%;
    height: 100vh;
}

.second-right-transmission {
    background-image: url("../../../../public/images/productos/Transmision/transmission-gera-ep-ficha.jpg");
    width: 100%;
    height: 100vh;
}

.second-central-transmission {
    background-image: url("../../../../public/images/productos/Transmision/transmission-gera-ep.jpg");
    width: 100%;
    height: 100vh;
}

/* Tercer Split */

.third-left-transmission {
    background-image: url("../../../../public/images/productos/Transmision/transmission-gera-mp-80w-90-presentacion.jpg");
    width: 100%;
    height: 100vh;
}

.third-right-transmission {
    background-image: url("../../../../public/images/productos/Transmision/transmission-gera-mp-80w-90-ficha.jpg");
    width: 100%;
    height: 100vh;
}

.third-central-transmission {
    background-image: url("../../../../public/images/productos/Transmision/transmission-gera-mp-80w-90.jpg");
    width: 100%;
    height: 100vh;
}

/* Cuarto Split */

.fourth-left-transmission {
    background-image: url("../../../../public/images/productos/Transmision/transmission-gera-mp-85w-140-presentacion.jpg");
    width: 100%;
    height: 100vh;
}

.fourth-right-transmission {
    background-image: url("../../../../public/images/productos/Transmision/transmission-gera-mp-85w-140-ficha.jpg");
    width: 100%;
    height: 100vh;
}

.fourth-central-transmission {
    background-image: url("../../../../public/images/productos/Transmision/transmission-gera-mp-85w-140.jpg");
    width: 100%;
    height: 100vh;
}

/* Fin Splits */

@media (min-width: 600px) {
    .title-transmission {
        font-size: 2rem;
    }

    .main {
        flex-direction: row;
    }

    .second-main {
        flex-direction: row;
    }

    .split {
        width: 50% !important;
        transition: width 1s ease;
    }

    .second-split {
        width: 50%;
        transition: width 1s ease;
    }

    .hover-left .left {
        width: 80%;
    }

    .hover-left .right {
        width: 80%;
    }

    .hover-left .central {
        width: 20%;
    }

    .hover-right .left {
        width: 80%;
    }

    .hover-right .right {
        width: 80%;
    }

    .hover-right .central {
        width: 20%;
    }

    .hover-central .central {
        width: 80%;
    }

    .hover-central .left {
        width: 20%;
    }

    .hover-central .right {
        width: 20%;
    }

    /* segundo hover */

    .hover-second-left .left {
        width: 80%;
    }

    .hover-second-left .right {
        width: 80%;
    }

    .hover-second-left .central {
        width: 20%;
    }

    .hover-second-right .left {
        width: 80%;
    }

    .hover-second-right .right {
        width: 80%;
    }

    .hover-second-right .central {
        width: 20%;
    }

    .hover-second-central .central {
        width: 80%;
    }

    .hover-second-central .left {
        width: 20%;
    }

    .hover-second-central .right {
        width: 20%;
    }
}