:root {
    --background-dark: #2d3548;
    --text-light: rgba(255, 255, 255, 0.6);
    --text-lighter: rgba(255, 255, 255, 0.9);
    --spacing-s: 8px;
    --spacing-m: 16px;
    --spacing-l: 24px;
    --spacing-xl: 32px;
    --spacing-xxl: 64px;
    --width-container: 1200px;
}

.producot-principal {
    height: 100%;
    font-size: 14px;
}

@-webkit-keyframes AnimateBG {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@keyframes AnimateBG {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.producto-secundario {
    height: 100%;
}

.hero-section {
    align-items: flex-start;
    background-image: linear-gradient(-45deg, #B8D9EC 0%, #B8D9EC 20%, #B8D9EC 40%, #FF6318 60%, #FF6318 80%, #FF6318 100%);
    display: flex;
    min-height: 100%;
    justify-content: center;
    padding: var(--spacing-xxl) var(--spacing-l);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 300% 300%;
    -webkit-animation: AnimateBG 20s ease infinite;
    animation: AnimateBG 20s ease infinite;
}

.poster-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: var(--spacing-l);
    grid-row-gap: var(--spacing-l);
    max-width: var(--width-container);
    width: 100%;
}

@media(min-width: 540px) {
    .poster-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media(min-width: 960px) {
    .poster-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}

#poster-1 {
    background-image: url(../../../public/images/categorias/producto.webp);
}

#poster-2 {
    background-image: url(../../../public/images/categorias/producto-2.webp);
}

#poster-3 {
    background-image: url(../../../public/images/categorias/producto-3.webp);
}

#poster-4 {
    background-image: url(../../../public/images/categorias/producto-4.webp);
}

#poster-5 {
    background-image: url(../../../public/images/categorias/producto-5.webp);
}

#poster-6 {
    background-image: url(../../../public/images/categorias/producto-6.webp);
}

#poster-7 {
    background-image: url(../../../public/images/categorias/producto-7.webp);
}

#poster-8 {
    background-image: url(../../../public/images/categorias/producto-8.webp);
}

.poster {
    list-style: none;
    position: relative;
    border: none !important;
    --bs-card-bg: none;
    background-color: var(--bs-card-bg)
}

.poster:nth-child(2) {
    filter: hue-rotate(0deg) brightness(1) !important;
}

.poster:nth-child(3) {
    filter: hue-rotate(0deg) brightness(1) !important;
}

.poster:nth-child(4) {
    filter: hue-rotate(0deg) brightness(1) !important;
}

.poster:before {
    content: '';
    display: block;
    padding-bottom: 150%;
    width: 100%;
}

.poster__background {
    background-size: cover;
    background-position: center;
    border-radius: var(--spacing-l);
    bottom: 0;
    filter: brightness(0.75) saturate(1.2) contrast(0.85);
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform-origin: center;
    transform: scale(1) translateZ(0);
    transition:
        filter 200ms linear,
        transform 200ms linear;
}

.poster:hover .poster__background {
    transform: scale(1.05) translateZ(0);
}

.poster-grid:hover>.poster:not(:hover) .poster__background,
.poster-grid:hover>.poster:not(:hover) .poster__heading {
    filter: brightness(0.5) saturate(0) contrast(1.2) blur(20px);
}

.poster__content {
    left: 0;
    padding: var(--spacing-l);
    position: absolute;
    top: 0;
}

.poster__category {
    color: var(--text-light);
    font-size: 0.9rem;
    margin-bottom: var(--spacing-s);
    text-transform: uppercase;
}

.poster__heading {
    color: var(--text-lighter);
    font-size: 1.9rem;
    text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
    line-height: 1.4;
    word-spacing: 100vw;
}